<header class="img-background mb-5 mt-n3" [style.backgroundImage]="'url(./assets/will-li-OWtRU2iLL_c-unsplash.jpg)'" parallax>
  <div class="row text-center align-items-center justify-content-center overflow-hidden" style="height: 350px;">
    <div class="col-md-8">
      <h1 class="display-3 mb-0 text-white" style="filter: blur(0)">Wise Guy Investor</h1>
      <p class="lead text-white">{{ 'Financial Learning Simplified' | uppercase }}</p>
    </div>
  </div>
</header>

<section class="container">
  <div class="row">
    <div class="col-md-6" *ngIf="!(posts$ | async)">
      <div class="card p-3 shadow-sm">
        <app-skeleton-text view="fb"></app-skeleton-text>
      </div>
    </div>

    <div class="col-md-6" *ngFor="let post of posts$ | async | slice: 0:2">
      <app-post [post]="post"></app-post>
    </div>
  </div>

  <div class="text-center mt-4">
    <a class="btn btn-secondary text-light" routerLink="/blog">See more episodes</a>
  </div>
</section>
