<header class="mb-4">
  <div class="text-center">
    <h2>Welcome to our blog</h2>
    <div *ngIf="user && auth.canEdit(user)">
      <a [routerLink]="['/blog/posts']" class="btn btn-light btn-sm">Manage</a>
    </div>
  </div>
</header>

<div class="jumbotron p-4 p-md-5 text-white rounded bg-dark" *ngIf="featuredPost">
  <div class="col-md-8 px-0">
    <h1 class="display-5 font-italic">{{ featuredPost.title }}</h1>
    <p class="lead my-3"><span [innerHTML]="featuredPost.text | slice: 0:100"></span></p>
    <p class="lead mb-0">
      <a [routerLink]="['/blog', featuredPost.id]" class="text-white font-weight-bold">Continue reading..</a>
    </p>
  </div>
</div>

<div class="row">
  <div class="col-md-6" *ngIf="!(posts$ | async)">
    <div class="card p-3 mb-4 shadow-sm">
      <app-skeleton-text view="fb"></app-skeleton-text>
    </div>
  </div>
  <div class="col-md-6" *ngFor="let post of posts$ | async | slice: 0:6">
    <app-post [post]="post"></app-post>
  </div>
</div>

<!-- <div class="card bg-dark mb-4 text-white" *ngIf="featuredPost">
  <div class="img-background rounded" style="height:250px"
    [style.backgroundImage]="util.getSanitizedStyle(featuredPost.photoURL)">
  </div>

  <div class="card-img-overlay">
    <h1 class="display-5 font-italic">{{featuredPost.title}}</h1>
    <p class="lead my-3"><span [innerHTML]="featuredPost.text | slice: 0: 100"></span></p>
    <p class="lead mb-0">
      <a [routerLink]="['/blog', featuredPost.id]" class="text-white font-weight-bold">Continue reading..</a>
    </p>
  </div>
</div> -->
