<header class="text-center mb-5">
  <h1>Welcome to my channel</h1>
</header>

<div class="row justify-content-center">
  <div class="col-auto">
    <iframe width="560" height="315"
      src="https://www.youtube.com/embed/videoseries?list=PLOSYzWYj6R8Jqu9_VxarNveJOt2coUQew" frameborder="0"
      allow="autoplay; encrypted-media" allowfullscreen></iframe>
  </div>
</div>
