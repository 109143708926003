<header class="mb-4">
  <h3>Users</h3>
</header>

<div *ngFor="let user of users$ | async" class="media mb-3">
  <img [src]="user.photoURL" class="rounded-circle img-sm mr-3" alt="" />

  <div class="media-body">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="1rem">
      <div>
        <p class="lead mb-0">{{ user.displayName }}</p>
        <p>{{ user.email }}</p>
      </div>

      <button class="btn btn-sm btn-outline-secondary" [routerLink]="[user.uid]">Edit</button>
    </div>
  </div>
</div>
