<nav class="navbar navbar-expand-md sticky-top navbar-light bg-white">
  <div class="container">
    <a routerLink="/" class="navbar-brand text-muted">
      {{ appTitle }}
    </a>

    <button
      class="navbar-toggler"
      type="button"
      (click)="isCollapsed = !isCollapsed"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [ngClass]="{ show: !isCollapsed }">
      <ul class="navbar-nav mr-auto"></ul>

      <!-- AUTH -->
      <nav *ngIf="user" class="navbar-nav ml-auto">
        <ng-container *ngIf="authService.currentUser?.roles?.editor">
          <a class="nav-link" routerLink="/blog/posts" routerLinkActive="active">Posts</a>
          <a class="nav-link" routerLink="/accounts/users" routerLinkActive="active">Users</a>
          <span class="navbar-text">|</span>
        </ng-container>
        <a class="nav-link" routerLink="/about" routerLinkActive="active">About me</a>
        <a class="nav-link" routerLink="/">Home</a>
        <a class="nav-link" routerLink="/blog" routerLinkActive="active">Blog</a>
        <a class="nav-link" routerLink="/accounts/edit" style="padding-top: 7px">
          <img *ngIf="user" [src]="user?.photoURL" alt="" class="img-xs rounded-circle mr-2" />
          <span class="d-inline d-md-none">{{ user?.displayName }}</span>
        </a>
      </nav>

      <!-- ANONYMOUS -->
      <nav *ngIf="!user" class="navbar-nav ml-auto no-select">
        <a class="nav-link" routerLink="/about">About me</a>
      </nav>
    </div>
  </div>
</nav>

<main class="pt-3" [ngClass]="{ container: !disableContainer }">
  <router-outlet></router-outlet>
</main>

<footer class="my-5 py-5 border-top container">
  <div class="row">
    <div class="col-12 col-md">
      <h3>WiseGuy Investor</h3>
      <small class="d-block mb-3 text-muted">©{{ year }}</small>
    </div>
    <div class="col-6 col-md">
      <h5>Social</h5>
      <nav fxLayout="column" fxLayoutGap="8px">
        <a class="text-muted" href="https://www.youtube.com/channel/UCPdod6Qvi-0t_l-qJrLDl0w" target="_blank">
          <i class="fa fa-fw fa-youtube"></i> Youtube Channel
        </a>
        <a class="text-muted" href="https://www.facebook.com/wiseguyinvestor" target="_blank">
          <i class="fa fa-fw fa-facebook"></i> Facebook
        </a>
        <a class="text-muted" href="https://www.instagram.com/wiseguyinvestor/" target="_blank">
          <i class="fa fa-fw fa-instagram"></i>
          Instagram
        </a>
      </nav>
    </div>
    <div class="col-6 col-md">
      <h5>About</h5>
      <nav class="nav" fxLayout="column" fxLayoutGap="8px">
        <a class="text-muted" routerLink="/about">About</a>
        <a class="text-muted" (click)="authService.loginGoogle()">Login</a>
      </nav>
    </div>
  </div>
</footer>

<div class="fb-customerchat" page_id="317671382361672"></div>
