export const firebaseConfig = {
  apiKey: 'AIzaSyCar7ZgSXx3bIO1rMycjZhe7UjwGewCXbE',
  authDomain: 'wiseguyapp.firebaseapp.com',
  databaseURL: 'https://wiseguyapp.firebaseio.com',
  projectId: 'wiseguyapp',
  storageBucket: 'wiseguyapp.appspot.com',
  messagingSenderId: '423044829241',
  appId: '1:423044829241:web:f316ee608676939bbf7de8',
  measurementId: 'G-QNHVDDT3T6',
};
