<div class="row no-gutters overflow-hidden flex-md-row mb-3 position-relative" [ngClass]="{ 'border rounded shadow-sm': !admin }">
  <div class="col p-4 d-flex flex-column position-static">
    <strong class="d-inline-block mb-2 text-secondary">{{ post.category }}</strong>
    <h3 class="mb-0">{{ post.title }}</h3>
    <p class="mb-1">
      <span class="text-warning" *ngIf="admin">{{ post.draft ? 'Draft &middot; ' : '' }}</span>
      <span class="text-muted">{{ post.createDate?.seconds * 1000 | date }}</span>
    </p>
    <p class="mb-1" [innerHTML]="post.text | slice: 0:100"></p>
    <a [routerLink]="['/blog/posts', post.id]" class="stretched-link text-secondary" *ngIf="!admin">Continue reading</a>

    <div *ngIf="admin" fxLayout fxLayoutAlign="start center" fxLayoutGap="8px">
      <a [routerLink]="['/blog/posts', post.id]" class="btn btn-sm btn-outline-secondary">View</a>
      <a [routerLink]="['/blog/posts', post.id, 'edit']" class="btn btn-sm btn-outline-secondary">Edit</a>
      <button (click)="delete(post.id)" class="btn btn-sm btn-outline-medium">Delete</button>
    </div>
  </div>

  <div class="col-auto p-4 d-none d-lg-block">
    <div
      class="img-background rounded img-lg"
      style="height: 100%"
      [hidden]="!post.photoURL"
      [style.backgroundImage]="util.getSanitizedStyle(post.photoURL)"
    ></div>
  </div>
</div>
