<header class="img-background mb-5 mt-n3" [style.backgroundImage]="'url(./assets/about.jpg)'">
  <div class="row text-center align-items-center justify-content-center overflow-hidden" style="height: 350px">
    <div class="col-md-8">
      <h1 class="display-3 mb-0 text-white">Wise Guy Investor</h1>
      <p class="lead text-white">Find me on</p>

      <div class="btn-group">
        <a class="btn btn-danger" href="https://www.youtube.com/channel/UCPdod6Qvi-0t_l-qJrLDl0w" target="_blank"
          ><i class="fa fa-fw fa-youtube"></i>
        </a>
        <a class="btn btn-light" href="https://www.instagram.com/wiseguyinvestor/" target="_blank"
          ><i class="fa fa-fw fa-instagram"></i>
        </a>
        <a class="btn btn-primary" href="https://www.facebook.com/wiseguyinvestor" target="_blank"
          ><i class="fa fa-fw fa-facebook"></i>
        </a>
      </div>
    </div>
  </div>
</header>

<section class="container">
  <div class="card mb-4">
    <div class="card-body p-5 text-center">
      <h4>Who am I?</h4>
      <p class="lead">
        Entrepreneur and Investment guru, Warren shares his insights and breaks down the crazy world of investing by allowing
        people to understand what they can do with their money and where to put it.
      </p>

      <h4 class="mt-5">What it's all about?</h4>
      <p class="lead">
        In the Uber-complex world of Investing and Stocks, Shares and volatile markets, Wise Guy Investor simplifies the nature of
        finances and goes through an understanding of money and what it can do for you.
      </p>

      <h4 class="mt-5">Why?</h4>
      <p class="lead">
        I believe that the get started barrier can be crossed by mostly anyone. A map of whats possible and what fits is more
        important to start with than knowing details about an area without knowing where it sits on the map.
      </p>
    </div>
  </div>

  <div class="card">
    <div class="card-body p-5 text-center">
      <h4 class="card-title">See more videos</h4>
      <p>Look us up on Patreon and subscribe</p>

      <p class="card-text text-center mb-3">
        <a href="https://www.patreon.com/wiseguyinvestor" class="d-block mx-auto" target="_blank">
          <img src="../../assets/patreon_navy.jpg" alt="Wise Guy Investor!" style="width: 150px;" />
        </a>
      </p>

      <p class="card-text mb-3">
        <a
          href="https://www.patreon.com/bePatron?u=15367607"
          class="d-block mx-auto"
          data-patreon-widget-type="become-patron-button"
          target="_blank"
        >
          <img src="../../assets/become_a_patron_button@2x.png" alt="Become a Patron!" style="max-width: 150px" />
        </a>
      </p>
      <script async src="https://c6.patreon.com/becomePatronButton.bundle.js"></script>
    </div>
  </div>
</section>
