<div class="card" *ngIf="user">
  <div class="card-body">
    <div class="row mb-2">
      <div class="col-sm-3">
        <nav class="nav flex-column">
          <a class="nav-link active">Edit Profile</a>

          <a routerLink="" *ngIf="currentUser?.uid == user?.uid" (click)="logout()" class="nav-link mt-3">Logout</a>
        </nav>
      </div>

      <div class="col-sm-9 col-xs-12">
        <div class="text-center mb-3">
          <div [hidden]="showCropper">
            <div class="d-flex justify-content-center">
              <div [style.background]="util.getSanitizedStyle(user?.photoURL)" class="rounded-circle img-background img-lg"></div>
            </div>
          </div>
        </div>

        <form #userForm="ngForm">
          <div class="row">
            <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
              <div class="form-group">
                <label>Name</label>
                <input type="text" [(ngModel)]="user.displayName" name="name" required placeholder="Name" class="form-control" />
              </div>
              <div class="form-group">
                <label>Email</label>
                <input type="email" [(ngModel)]="user.email" name="email" required placeholder="Email" class="form-control" />
              </div>
              <div class="form-group">
                <label>Phone</label>
                <input type="tel" [(ngModel)]="user.phoneNumber" name="phone" placeholder="Phone number" class="form-control" />
              </div>
              <div class="form-group">
                <label>Bio</label>
                <input type="text" [(ngModel)]="user.bio" name="about" placeholder="Bio" class="form-control" />
              </div>

              <ng-container *ngIf="user && user.roles && authService.currentUser?.roles?.admin">
                <p class="lead">Roles</p>

                <div *ngFor="let role of allRoles; let i = index">
                  <div class="form-check">
                    <input
                      #inputEl
                      class="form-check-input"
                      type="checkbox"
                      [id]="'role' + i"
                      [checked]="user.roles[role]"
                      (change)="user.roles[role] = inputEl.checked"
                      name="role"
                    />
                    <label class="form-check-label" [for]="'role' + i">
                      {{ role | titlecase }}
                    </label>
                  </div>
                </div>
              </ng-container>

              <button (click)="update(user)" class="btn btn-primary btn-block">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- <div class="text-right mt-5">
      <div class="btn-group">
        <button class="btn btn-link text-dark" (click)="deactivate()">Temporarily disable my account</button>
      </div>
    </div> -->
