<header class="mb-4" fxLayout fxLayoutAlign="start end" fxLayoutGap="1rem">
  <h3 class="mb-0">Blog Posts</h3>
  <button [routerLink]="['create']" class="btn btn-primary">Write post</button>
</header>

<div class="row justify-content-center">
  <div class="col-md-8 col-lg-6">
    <app-skeleton-text *ngIf="!(posts$ | async)"></app-skeleton-text>

    <p class="lead text-center text-muted" *ngIf="(posts$ | async)?.length == 0">Get started. Write a post..</p>
  </div>

  <div class="col-md-10" *ngFor="let post of posts$ | async">
    <app-post [post]="post" [admin]="true"></app-post>
  </div>
</div>
