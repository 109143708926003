<div class="row justify-content-center">
  <div class="col-lg-8">
    <div class="card p-3" *ngIf="!(post$ | async)">
      <app-skeleton-text view="fb"></app-skeleton-text>
    </div>

    <div class="card" *ngIf="post$ | async as post">
      <div
        class="img-background rounded-top card-img-top"
        style="height: 64px"
        [hidden]="!post.photoURL"
        [style.backgroundImage]="util.getSanitizedStyle(post.photoURL)"
      ></div>

      <div class="card-body">
        <div class="fr-video mb-3" *ngIf="post.videoURL">
          <iframe
            [src]="util.getSanitizedUrl(post.videoURL)"
            width="640"
            height="360"
            frameborder="0"
            allowfullscreen=""
          ></iframe>
        </div>

        <h1 class="card-title font-italic">{{ post.title }}</h1>

        <div class="my-3" [innerHTML]="util.getSanitizedHtml(post.text)"></div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" *ngIf="authService.canEdit(user)">
          <a [routerLink]="['/blog/posts']" class="btn btn-outline-dark btn-sm">Manage</a>
          <a [routerLink]="['/blog/posts', post.id, 'edit']" class="btn btn-outline-secondary btn-sm">Edit</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="text-center my-4">
  <a [routerLink]="['/blog']" class="btn btn-light mr-4">Return to Blog</a>
  <a [routerLink]="['/']" class="btn btn-light">Home</a>
</div>
